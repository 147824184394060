import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import SubjectModal from '../pannel/subModal';
import Accordion from 'react-bootstrap/Accordion';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const Papers = () => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setSubjectData({});
    }

    const [nameData, setNameData] = useState([]);

    useEffect(() => {
        viewName();
    }, []);

    const viewName = () => {
        axios.get(`${process.env.REACT_APP_MAIN_API}/getExamName`)
            .then((res) => {
                console.log(res);
                setNameData(res.data.examname);
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const [viewSubData, setViewSubData] = useState([]);

    useEffect(() => {
        viewSubject();
    }, [])


    const viewSubject = () => {
        axios.get(`${process.env.REACT_APP_MAIN_API}/getSubject`)
            .then((res) => {
                console.log(res);
                setViewSubData(res.data.subject);
            })
            .catch((err) => {
                console.log(err.response);
            })
    }


    const [subjectData, setSubjectData] = useState({
        subName: '',
        name: '',
        syllabus: ''
    });

    const handleInput = (e) => {
        setSubjectData({
            ...subjectData,
            [e.target.name]: e.target.value
        })

    }


    const [subName, setSubName] = useState([]);

    const handleSubject = (e) => {
        console.log(e.target.value);
        setSubName(e.target.value);


        // setSubName(e)
        // setSubjectData({
        //     ...subjectData,
        //     [e.target.name]: e.target.value
        // })
    }

    const handleSubmit = () => {
        console.log(subjectData);

        const postData = new FormData();
        postData.append('subName', subjectData.subName);
        postData.append('name', subjectData.name);
        postData.append('syllabus', subjectData.syllabus);

        axios.post(`${process.env.REACT_APP_MAIN_API}/postSubject`, postData)
            .then((res) => {

                console.log(res);
                viewSubject();
                handleClose();
                alert('Successfully submitted');
            })
            .catch((err) => {
                console.log(err.response);
            })
    }


    return (
        <section>
            <div>
                <Button onClick={handleOpen} variant="contained" className='my-3' style={{ 'float': 'right' }} size="small">+ Add Subjects</Button>
                <SubjectModal handleSubject={handleSubject} subjectData={subjectData} handleSubmit={handleSubmit} open={open} handleClose={handleClose} nameData={nameData} subName={subName} handleInput={handleInput} />
            </div>

            {/* <div>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '100%' }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">ID</StyledTableCell>
                                <StyledTableCell align="center">Exam Name</StyledTableCell>
                                <StyledTableCell align="center">Subject</StyledTableCell>
                                <StyledTableCell align="center">Syllabus</StyledTableCell>
                                <StyledTableCell align="center">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {viewSubData.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell component="th" scope="row" align="center">
                                        {row.id}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{row.subName}</StyledTableCell>
                                    <StyledTableCell align="center">{row.name}</StyledTableCell>
                                    <StyledTableCell align="center">{row.syllabus}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        <button className='btn btn-success btn-sm' title='edit'>
                                            <DriveFileRenameOutlineIcon fontSize="small" />
                                        </button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div> */}

            <div>
                <Accordion>
                    {viewSubData.map((row) => (
                        <Accordion.Item eventKey={row.id}>
                            <Accordion.Header>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-6">
                                            <strong>
                                                {row.subName}
                                            </strong>
                                        </div>
                                        <div className="col-6 text-end">
                                            <button className='btn btn-success btn-sm'>+ Syllabus</button>
                                            <Button onClick={handleOpen} variant="contained" style={{ 'float': 'right' }} size="small">+ Add Subjects</Button>
                                        </div>
                                    </div>
                                </div>

                            </Accordion.Header>

                            <Accordion.Body>

                                {/* child */}
                                <Accordion>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>Accordion Item #2</Accordion.Header>
                                        <Accordion.Body>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do

                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>



                            </Accordion.Body>
                        </Accordion.Item>

                    )
                    )}


                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Accordion Item #2</Accordion.Header>
                        <Accordion.Body>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do

                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>


            </div>




        </section>
    )
}

export default Papers;