import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import JoditEditor from 'jodit-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function RichText(props) {

    const editor = useRef(null);



    const config = {
        uploader: {
            insertImageAsBase64URI: true
        },
        height: 100,
        triggerChangeEvent: true,
        readonly: false,

        buttons: ['bold', 'italic', 'underline', 'ol', 'ul', 'brush', 'redo', 'undo', 'hr', 'fullsize', 'image' ],
        buttonsMD: ['bold', 'italic'],
        buttonsXS: ['bold', 'fullsize'],

        showXPathInStatusbar: false,
        showCharsCounter: false,
        showWordsCounter: false,
        toolbarAdaptive: true,
        toolbarSticky: true,



        image: {
            selectImageAfterClose: true,
            showPreview: true,
            useImageEditor: true
        }
    }

    const Jodit = () => {
        return useMemo(() => (
            <JoditEditor
                ref={editor}
                config={config}
                value={props.value}
                onChange={props.handleForm}
            />
        ), [])
    }


    return (
        <>
            {Jodit()}
        </>
    )
}