import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import PointsTable from '../table/points';
import DialogModal from '../modal/modal'
import { TextField, Input, DialogTitle, DialogActions } from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import api from '../../API/api';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const PointsMaster = () => {

    const [backdroploader, setBackdropLoader] = useState(false);
    const [medals, setMedals] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = useState('')
    const [deleteDialog, setDeleteDialog] = React.useState(false);
    const handleOpen = () => { setDeleteDialog(false); setOpen(true); setPointsInput(''); setImage(''); }
    const handleClose = () => {
        setOpen(false);
    }
    const [pointsInput, setPointsInput] = useState({
        name: '',
        min: '',
        max: ''
    })

    const [image, setImage] = useState();


    const handleInput = (e) => {
        setPointsInput({
            ...pointsInput,
            [e.target.name]: e.target.value
        })
    }

    const getValues = () => {
        // console.log(pointsInput, 'poin');
        // console.log(image, 'image');

        const data = new FormData();
        if (pointsInput.id) {
            data.append('id', pointsInput.id)
        }
        data.append('name', pointsInput.name)
        data.append('min', pointsInput.min)
        data.append('max', pointsInput.max)
        data.append('image', image)

        for (const value of data.values()) {
            console.log(value, 'formdata values');
        }

        axios.post(`${process.env.REACT_APP_MAIN_API}/medalMaster`, data)
            .then((res) => {
                setOpen(false)
                console.log(res, 'change update');
                Swal.fire(
                    'Good job!',
                    'Medal Created!',
                    'success'
                )
            })
            .catch(err => {
                console.log(err, 'medal error');
            })
    }

    const openDelete = (data) => {
        console.log(data, 'data2');
        setDeleteDialog(true);
        setOpen(true);
        setPointsInput('');
        setValue(data)
    }


    const handleDelete = () => {
        // return;
        api.deleteMedalMaster(value.id).then(res => {
            console.log(res, 'res');
            Swal.fire({
                title: 'Deleted',
                text: 'Medal is disabled',
            });
            // setDeleteDialog(false)
            setOpen(false);
            getMedals();
        }).catch(err => {
            console.log(err, 'err');
            Swal.fire({
                title: 'Something Went wrong',
                text: 'Please try again',
            });
        })
    }

    useEffect(() => {
        setBackdropLoader(true)
        getMedals();
    }, []);

    const getMedals = () => {
        api.getMedals().then((res) => {
            console.log(res);
            setMedals(res.data.medals)
            setBackdropLoader(false)
        })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const handleEdit = data => {
        setDeleteDialog(false)
        setOpen(true)
        console.log(data, 'onPres data');
        setPointsInput({
            ...pointsInput,
            name: data.name,
            min: data.min,
            max: data.max,
            id: data.id
        })
        setImage(data)
    }

    return (
        <section>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdroploader}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div>
                <h3>Points Master</h3>
                <Button onClick={handleOpen} variant="contained" style={{ 'float': 'right' }} size="small">+ Add Points</Button>
                <DialogModal
                    open={open} setOpen={setOpen} handleCloseMove={handleClose}
                    form={
                        !deleteDialog ?
                            <AddPointModal
                                handleInput={handleInput}
                                getValues={getValues}
                                setImage={setImage}
                                pointsInput={pointsInput}
                                image={image}
                            />
                            :
                            <>
                                <DialogTitle id="responsive-dialog-title">
                                    {"Are you sure you want to Delete ?"}
                                </DialogTitle>
                                <DialogActions>
                                    <Button autoFocus onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button onClick={handleDelete} autoFocus>
                                        {
                                            value.isActive === 1 ?
                                                'Make Disable'
                                                :
                                                'Make Enable'
                                        }
                                    </Button>
                                </DialogActions>
                            </>
                    }
                />
            </div>
            <div>
                <PointsTable medals={medals} handleEdit={handleEdit} open={open} setOpen={setOpen} handleDelete={handleDelete} openDelete={openDelete} />
            </div>

        </section>
    )
}




const AddPointModal = ({ handleInput, getValues, setImage, pointsInput, image }) => {
    return (
        <div
            className='w-100'
        >
            <div
                className='row'
            >
                <p>Add Points Master</p>
                <TextField id="filled-basic" label="Name" variant="filled"
                    onChange={(e) => handleInput(e)} name="name"
                    value={pointsInput?.name}
                />
                <TextField id="filled-basic" label="Minimum" variant="filled"
                    onChange={(e) => handleInput(e)} name="min"
                    value={pointsInput?.min}
                />
                <TextField id="filled-basic" label="Maximum" variant="filled"
                    onChange={(e) => handleInput(e)} name="max"
                    value={pointsInput?.max}
                />

                <div class="row my-4">
                    <div
                        className='col-md-6'
                    >
                        <label>Upload Medal Image</label>
                        <Input
                            type="file"
                            className='form-control'
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                    </div>
                    {
                        image &&
                        <div
                            className='col-md-6'>
                            <img src={`${process.env.REACT_APP_BACKEND}medals/${image?.image}`} width={100} height={100} alt="medal" />
                        </div>
                    }
                </div>
                <Button
                    variant="contained"
                    onClick={getValues}
                >
                    Submit
                </Button>
            </div>
        </div>
    )
}


export default PointsMaster;