import React, { useEffect, useState } from 'react'
// import AddQuestion from '../form/addQuestion';
// import { Button } from '@mui/material';
import { Paper } from '@mui/material';
import api from '../../API/api';
import Swal from 'sweetalert2';
// import LinearWithValueLabel from '../pannel/progressbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useParams, useLocation } from "react-router-dom"
import PreviewQuestion from '../pannel/previewQuestion';
// import EditQuestions from '../question/editQuestions';
import NewFormQuestion from '../form/newformQuestion';
import axios from 'axios';


const EditQuestionPage = (props) => {
    const navigation = useNavigate();

    // here location is the props data
    const location = props;
    console.log(props.wholeArray, 'props for editmodal');


    // here location is the props data check above
    const prams = location.state;
    const sub_id = prams.id;
    const sub_name = prams.subname;
    const syl_id = prams.selfId;


    const [subject, setSubject] = useState();
    const [syllabus, setSyllabus] = useState();
    const [preview, setPreview] = useState(false);

    // for backdrop loader 
    const [backdroploader, setBackdropLoader] = React.useState(false);
    const handleClose = () => {
        setBackdropLoader(false);
    };
    const handleOpen = () => {
        setBackdropLoader(true);
    };
    // *************

    const [all, setAll] = useState(location.state.row);
    const [wrongAns, setWrongAns] = useState([]);
    const [question, setQuestion] = useState();
    const [rightans, setRightans] = useState('');
    const [ansDesc, setAnsDesc] = useState('');
    const [questionForm, setQuestionForm] = useState({
        q_id: location.state.row.q_id,
        ans_desc: location.state.row.description,
        difficulties: location.state.row.difficulties,
        question: location.state.row.question,
        // right_ans: location.state.row.ans && location.state.row.ans[0].answer,
        right_ans: location.state.row.ans && location.state.row.ans?.filter((r) => r.isRight == 1)[0].answer,
        subject_id: location.state.row.subject.id,
        syllabus_id: location.state.row.syllabus.id,
        class: location.state.row.class,
        ans_type: location.state.row.ans[0].question_type
        ,
    })


    useEffect(() => {
        location.state.row.ans.map((el) => {
            if (el.isRight === 1) {
                setQuestionForm({
                    ...questionForm,
                    right_ans: el.answer,
                })
                setRightans(el.answer);
            }
        })
    }, [prams])

    useEffect(() => {
        setQuestionForm({
            ...questionForm,
            syllabus_id: syl_id
        })
    }, [])
    const calcelPreview = () => {
        setPreview(false);
    }

    const handleForm = (e) => {
        if (e.target) {
            setQuestionForm({
                ...questionForm,
                [e.target.name]: e.target.value
            })
        }

    }


    const handleQuestion = (e) => {
        setQuestion(e)
    }
    const handleRightAns = (e) => {
        if (questionForm.ans_type == "1") {
            setRightans(e)
        }
        if (questionForm.ans_type == "3") {
            // console.log(questionForm.ans_type );
            setRightans(e.target.value)
        }
    }
    const handleAnsDesc = (e) => {
        setAnsDesc(e)
    }

    useEffect(() => {
        setQuestionForm({
            ...questionForm,
            question: question
        })
    }, [question]);

    useEffect(() => {
        setQuestionForm({
            ...questionForm,
            right_ans: rightans
        })
    }, [rightans]);

    useEffect(() => {
        setQuestionForm({
            ...questionForm,
            ans_desc: ansDesc
        })
    }, [ansDesc]);

    useEffect(() => {
        // getPramsData();
        getSubject();
    }, [prams]);



    const getTheSyl = (id) => {
        setBackdropLoader(true);
        api.getTheSyl(id).then((res) => {
            setSyllabus(res.data.syllabus);
            setQuestionForm({
                ...questionForm,
                'syllabus_id': id,
                'subject_id': sub_id
            })
            setBackdropLoader(false);
        })
            .catch((err) => {
                console.log(err.response);
            })
    }
    const getSubject = () => {
        setBackdropLoader(true);
        api.getSubject()
            .then((res) => {
                setBackdropLoader(false);
                console.log(res);
                setSubject(res.data.subject)
                getTheSyl(syl_id);
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const handleChangeSubject = (e) => {
        getSyllabusBySub(e.target.value);
        setQuestionForm({
            ...questionForm,
            [e.target.name]: e.target.value
        })
    }

    const getSyllabusBySub = (id) => {
        setBackdropLoader(true);
        api.getSyllabusBySub(id)
            .then((res) => {
                setBackdropLoader(false);
                setSyllabus(res.data.syllabus);
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const handleSubmit = (tags, graphicAns) => {
        var wrongAnsCount = 0;
        var rightAnsCount = 0;
        let newTags;
        let fd = new FormData()
        if (questionForm.ans_type === '2') {
            graphicAns.map((a, index) => {
                if (a.type === 0) {
                    fd.append("Wimage" + wrongAnsCount, a.ans);
                    wrongAnsCount++;
                } else {
                    fd.append("Rimage" + rightAnsCount, a.ans);
                    rightAnsCount++;
                }
            })
        }
        else {
            let newTags = tags.map((t, index) => {
                fd.append("WA" + index, t);
                wrongAnsCount++;
                return;
            });

        }
        console.log(rightans, wrongAns, 'update wquestion');
        // console.log(rightAnsCount,wrongAnsCount,questionForm,graphicAns, 'check value tags');
        // return;
        fd.append("wrongAnsCount", wrongAnsCount);
        fd.append("rightAnsCount", rightAnsCount);
        fd.append("allGraphicAns", JSON.stringify(graphicAns));
        fd.append("q_id", questionForm.q_id);

        fd.append("ans_desc", questionForm.ans_desc);
        fd.append("difficulties", questionForm.difficulties);
        fd.append("question", questionForm.question);
        fd.append("right_ans", questionForm.right_ans);
        fd.append("subject_id", questionForm.subject_id);
        fd.append("syllabus_id", questionForm.syllabus_id);
        fd.append("class", questionForm.class && questionForm.class);
        fd.append("ans_type", questionForm.ans_type && questionForm.ans_type);

        for (const value of fd.values()) {
            console.log(value, 'formdata value');
        }
        // return;
        let api = process.env.REACT_APP_MAIN_API + '/editQuestion';
        // axios.post('http://127.0.0.1:8000/api/editQuestion', fd).then((res) => {
        axios.post(api, fd).then((res) => {
            console.log("res updated data", res);
            // return;
            if (res.data.status == 200) {
                props.setAllChange({
                    ...all,
                    data: Object.keys(props.wholeArray).map((key) =>(
                        props.wholeArray[key].q_id === all.q_id ?
                            {
                                ...props.wholeArray[key],
                                // isApprove: e.isApprove === null ? 1 : null
                                ans_desc: questionForm.ans_desc,
                                difficulties: questionForm.difficulties,
                                allGraphicAns: JSON.stringify(graphicAns),
                                question: questionForm.question,
                                right_ans: questionForm.right_ans,
                                subject_id: questionForm.subject_id,
                                syllabus_id: questionForm.syllabus_id,
                                class: questionForm.class,
                            }
                            :
                            props.wholeArray[key])
                    )
                });
                Swal.fire({
                    title: 'Updated',
                    text: 'Question Data Updated Successfully ',
                    icon: 'success'
                });
                props.setOpen(false)
                // navigation(-1)
            }
            else {
                Swal.fire({
                    title: 'Opss',
                    text: 'Something went wrong ! ',
                    icon: 'error'
                });
                props.setOpen(false)
            }
        })
            .catch((err) => {
                console.log(err, 'handle edit save');
                props.setOpen(false)
                Swal.fire({
                    title: 'Opss',
                    text: 'Something went wrong ! ',
                    icon: 'error'
                });
            })
    }

    useEffect(() => {
        setWrongAns(location.state.wrong)
    }, [location.state])

    console.log(prams && prams, 'subject');
    return (
        <>

            <PreviewQuestion open={preview} calcelPreview={calcelPreview} questionForm={questionForm} wrongAns={wrongAns} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdroploader}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* <LinearWithValueLabel /> */}
            <section>
                <div className='row'>
                    <div>
                        {/* <button className='btn btn-success btn-sm' onClick={() => navigation(-1)}> Back </button> */}
                    </div>
                    <Paper>
                        <div className='my-3'>
                        </div>

                        <div>
                            <div className="row">
                                <div className="col-12">
                                    <h3 className='text-center'>
                                        Edit Questions
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </Paper>
                </div>

                <div>
                    <div className="row">
                        <div className="col-12">
                            <Paper elevation={4} className='p-4 m-2'>
                                {/* <EditQuestions
                                    data={location.state.row} subject={subject && subject} syllabus={syllabus && syllabus} syllbusId={questionForm.syllabus_id} wrongAns={wrongAns} rightans={rightans}
                                // question={question} rightans={rightans}     
                                /> */}
                                <NewFormQuestion type={'edit'}
                                    questionForm={questionForm} handleForm={handleForm} handleChangeSubject={handleChangeSubject} handleRightAns={handleRightAns}
                                    subject={subject && subject} syllabus={syllabus && syllabus} syllbusId={questionForm.syllabus_id} handleQuestion={handleQuestion} handleAnsDesc={handleAnsDesc}
                                    rightans={rightans} wrongAns={wrongAns} setQuestionForm={setQuestionForm} handleSubmit={handleSubmit} all={all}
                                />
                            </Paper>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default EditQuestionPage;