import React, { useEffect, useState } from 'react'
import AddQuestion from '../form/addQuestion';
import { Paper } from '@mui/material';
import api from '../../API/api';
import Swal from 'sweetalert2';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from "react-router-dom"
import PreviewQuestion from '../pannel/previewQuestion';
import AuthUser from '../../API/token'
import NewFormQuestion from '../form/newformQuestion';
import axios from 'axios';
import AddQuestionMaths from '../form/addMaths';

const AddQuestionPage = (props) => {

    const { user, token } = AuthUser();

    // console.log("user", user.id);
    // console.log(token, 'token');
    const prams = useParams('id');
    const sub_id = prams.id;
    const sub_name = prams.sub;
    const syl_id = prams.selfId;


    const [subject, setSubject] = useState();
    const [syllabus, setSyllabus] = useState();
    const [preview, setPreview] = useState(false);

    // for backdrop loader 
    const [backdroploader, setBackdropLoader] = React.useState(false);
    const handleClose = () => {
        setBackdropLoader(false);
    };
    const handleOpen = () => {
        setBackdropLoader(true);
    };
    // *************


    const [question, setQuestion] = useState('');
    const [rightans, setRightans] = useState('');
    const [ansDesc, setAnsDesc] = useState('');
    const [questionForm, setQuestionForm] = useState({
        ans_desc: '',
        difficulties: '',
        question: '',
        right_ans: '',
        subject_id: '',
        syllabus_id: '',
        class: '',
        ans_type: "1",
        desc_type: 0,
    })

    const calcelPreview = () => {
        setPreview(false);
    }

    const handleForm = (e) => {
        if (e.target) {
            setQuestionForm({
                ...questionForm,
                [e.target.name]: e.target.value
            })
        }

    }


    const handleQuestion = (e) => { setQuestion(e) }
    const handleRightAns = (e) => { 
        if(questionForm.ans_type === "1"){
            setRightans(e) 
        }
        if(questionForm.ans_type === "3"){
            setRightans(e.target.value) 
        }
        // setRightans(e.target.value) 
    }
    const handleAnsDesc = (e) => { setAnsDesc(e) }

    useEffect(() => {
        setQuestionForm({
            ...questionForm,
            question: question
        })
    }, [question]);

    useEffect(() => {
        setQuestionForm({
            ...questionForm,
            right_ans: rightans
        })
    }, [rightans]);

    useEffect(() => {
        setQuestionForm({
            ...questionForm,
            ans_desc: ansDesc
        })
    }, [ansDesc]);

    useEffect(() => {
        getPramsData();
    }, [prams]);

    const getPramsData = () => {
        // if come from 'Subjects' 
        if (prams.id) {
            console.log("found", sub_id, sub_name, syl_id);
            setSubject(
                [{
                    id: sub_id,
                    subName: sub_name
                }]
            )

            getTheSyl(syl_id);
        }
        else {
            // if come from 'Add Question' 
            getSubject();
        }
    }

    const getTheSyl = (id) => {
        console.log("qq=>", questionForm);

        setBackdropLoader(true);
        api.getTheSyl(id).then((res) => {
            console.log(res)
            setSyllabus(res.data.syllabus);
            setQuestionForm({
                ...questionForm,
                'syllabus_id': id,
                'subject_id': sub_id
            })
            setBackdropLoader(false);
        })
            .catch((err) => {
                console.log(err.response);
            })

    }


    const getSubject = () => {
        setBackdropLoader(true);
        api.getSubject()
            .then((res) => {
                setBackdropLoader(false);
                console.log(res);
                setSubject(res.data.subject)
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const handleChangeSubject = (e) => {
        console.log("subject=>", e.target.value);
        getSyllabusBySub(e.target.value);
        setQuestionForm({
            ...questionForm,
            [e.target.name]: e.target.value
        })
    }

    const getSyllabusBySub = (id) => {
        setBackdropLoader(true);
        api.getSyllabusBySub(id)
            .then((res) => {
                setBackdropLoader(false);
                console.log("syl==>>", res);
                setSyllabus(res.data.syllabus);
            })
            .catch((err) => {
                console.log(err.response);
            })
    }


    const [wrongAns, setWrongAns] = useState();
    const [graphicAns, setGraphicAns] = useState([]);

    const handleSubmit = (wrongAns, ansGraphics) => {
        console.log("all wrongAns", wrongAns);
        console.log("all ans=>1", ansGraphics);
        console.log("all ans=>", questionForm);
        // return;
        // Wrong ans are taking directly by passing it through function 
        // console.log(wrongAns);
        if (questionForm.ans_type === "2") {
            setGraphicAns(ansGraphics)
        } else {
            setWrongAns(wrongAns);
        }

        if (questionForm.question == '') {
            Swal.fire(
                'Need Data',
                'No Question Found!',
                'warning'
            )
            return;
        }
        if (questionForm.subject_id == '') {
            Swal.fire(
                'Need Data',
                'Please Select a Subject!',
                'warning'
            )
            return;
        }
        if (questionForm.syllabus_id == '') {
            Swal.fire(
                'Need Data',
                'Please select a syllabus!',
                'warning'
            )
            return;

        }
        setPreview(true);
        return;
        // preview open and save data from there 
    }

    const saveQuestion = (questionForm, wrongAns) => {
        console.log(wrongAns,questionForm, '1wrong ans');
        // return;
        setBackdropLoader(true);
        let fd = new FormData()
        var wrongAnsCount = 0;
        var rightAnsCount = 0;
        if (questionForm.ans_type === "2") {
            graphicAns.map((a, index) => {
                // fd.append("Wimage" + wrongAnsCount, graphicAns[index]['ans']);
                if (a.type === 0) {
                    fd.append("Wimage" + wrongAnsCount, a.ans);
                    wrongAnsCount++;
                } else {
                    fd.append("Rimage" + rightAnsCount, a.ans);
                    rightAnsCount++;
                }
            })

        } else {
            for (var j = 0; j < wrongAns.length; j++) {
                fd.append('textWrongAnswers' + [j], wrongAns[j])
                wrongAnsCount++;
            }
        }

        fd.append('question', questionForm.question)
        fd.append('subject_id', questionForm.subject_id)
        fd.append('syllabus_id', questionForm.syllabus_id)
        fd.append('difficulties', questionForm.difficulties)
        fd.append('right_ans', questionForm.right_ans)
        fd.append('ans_desc', questionForm.ans_desc)
        fd.append('desc_type', questionForm.desc_type)
        fd.append('addedBy', user.id)
        fd.append('class', questionForm.class)
        fd.append('ans_type', questionForm.ans_type)
        fd.append('graphic_Count', graphicAns.length)
        fd.append('wrongAnsCount', wrongAnsCount)
        fd.append('rightAnsCount', rightAnsCount)
        // fd.append('textWrongAnswers', [wrongAns])
        // console.log(fd, wrongAns, user, 'wrng ansdewe');
        // return;
        // axios.post('http://127.0.0.1:8000/api/postQuestion', fd, wrongAns, user).then((res) => {
        axios.post(`${process.env.REACT_APP_MAIN_API}/postQuestion`, fd, wrongAns, user).then((res) => {
            console.log("ee", res);
            if (res.data.status === 200) {
                calcelPreview();
                Swal.fire(
                    'Question added successfully !',
                    'Draft Question',
                    'success'
                )

                setBackdropLoader(false);
            }
        })
            .catch((err) => {
                console.log(err.response);
            })

        return;
    }

    return (
        <>
            <PreviewQuestion open={preview} calcelPreview={calcelPreview} questionForm={questionForm} wrongAns={wrongAns} saveQuestion={saveQuestion} graphicAns={graphicAns} backdroploader={backdroploader}/>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdroploader}
                onClick={handleClose}
            >
                {/* <CircularProgress color="inherit" /> */}
                <img src="/assets/test.gif" alt="" />
                {/* AAAA */}
            </Backdrop>

            {/* <LinearWithValueLabel /> */}
            <section>
                <div className='row'>
                    <Paper>
                        <div className="col-12">
                            <h3 className='text-center'>Add Questions</h3>
                        </div>
                    </Paper>
                </div>

                <div>
                    <div className="row">
                        <div className="col-12">
                            <Paper elevation={4} className='p-4 m-2'>
                                {/* ==============>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> for testing purpose uncommnet this  <<<<<<<<<<<============================ */}
                                <NewFormQuestion type={'addQuestion'}
                                    subject={subject} handleChangeSubject={handleChangeSubject} syllabus={syllabus} questionForm={questionForm} handleForm={handleForm} handleSubmit={handleSubmit} handleQuestion={handleQuestion} handleRightAns={handleRightAns} handleAnsDesc={handleAnsDesc} prams={prams}
                                />
                                {/* ==============>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> for testing purpose uncommnet this <<<<<<<<<<<============================ */}

                                {/* <AddQuestionMaths
                                    subject={subject} handleChangeSubject={handleChangeSubject} syllabus={syllabus} questionForm={questionForm} handleForm={handleForm} handleSubmit={handleSubmit} handleQuestion={handleQuestion} handleRightAns={handleRightAns} handleAnsDesc={handleAnsDesc} prams={prams}

                                /> */}

                                {/* <AddQuestion subject={subject} handleChangeSubject={handleChangeSubject} syllabus={syllabus} questionForm={questionForm} handleForm={handleForm} handleSubmit={handleSubmit} handleQuestion={handleQuestion} handleRightAns={handleRightAns} handleAnsDesc={handleAnsDesc} /> */}

                            </Paper>
                        </div>
                    </div>
                </div>

            </section>
        </>
    )
}

export default AddQuestionPage;