import "./App.css";
import { Routes, Route } from "react-router-dom";
// import Dashboard from './AdminPages/dashboard/dashboard';
import Dash from "./AdminPages/dashboard/dash";
import AddExamType from "./AdminPages/Pages/examtype";
import AddExamName from "./AdminPages/Pages/examname";
import AddSubject from "./AdminPages/Pages/subject";
import Papers from "./AdminPages/Pages/paper";
import AddQuestionPage from "./AdminPages/Pages/addQuestionPage";
import DashboardPage from "./AdminPages/dashBoardPage";
import QuizPage from "./PublicPages/quizHomePage";
import ExamPage from "./PublicPages/examPage";
import AddSyllabus from "./AdminPages/Pages/addSyllabus";
import ViewQuestions from "./AdminPages/question/viewQuestions";
// import QuestionTemplate from './AdminPages/qtemplate/form';
import AddQuestionSets from "./AdminPages/qtemplate/addSets";
import AddQuestionTemplate from "./AdminPages/Pages/addQuesTemplate";
import AddUsers from "./AdminPages/Pages/users/addUser";
import ListUsers from "./AdminPages/Pages/users/listUsers";
import Login from "./login";
import EditQuestionPage from "./AdminPages/Pages/editQuestionPage";
import TemplatesList from "./AdminPages/table/templates";
import SetQuestionPaper from "./AdminPages/questionPaper/setQuestionPaper";
import GeneratedPaper from "./AdminPages/questionPaper/generatedPapaer";
import QuestionList from "./AdminPages/table/questionsAll";
import { ProSidebarProvider } from "react-pro-sidebar";
import AuthUser from "./API/token";
import EditQuestionSets from "./AdminPages/qtemplate/editSets";
import QuestionsFromExcel from "./AdminPages/Pages/QuestionsFromExcel";
import EditExcelQuestions from "./AdminPages/Pages/EditExcelQuestions";
import StudyMaterial from "./AdminPages/Pages/studyMaterial";
import StudyMaterialForm from "./AdminPages/form/studyMaterial";
import Reports from "./AdminPages/Pages/reports";
import TestPaper from "./AdminPages/question/questionTest";
import AddQuestionMaths from "./AdminPages/form/addMaths";
import AppUsers from "./AdminPages/Pages/appUsers";
import PointsMaster from "./AdminPages/Pages/points";
import RewardMaster from "./AdminPages/Pages/rewards";

// import AddQuestionTemplate from './AdminPages/Pages/addQuesTemplate';
import CreatePaper from "./AdminPages/Paper/createPaper";
import PaperPage from "./AdminPages/Paper/list";
import AppPackages from "./AdminPages/Pages/appPackage";

import NewsLetterSubscribersPage from "./AdminPages/newsletter/NewsletterSubscribers";

import store from "../src/redux/store";
import { Provider } from "react-redux";
import AppUserSubscribers from "./AdminPages/appUserSubscriptions/AppUserSubscribers";
import PushNotificationPage from "./AdminPages/notifications/push_notification.page";
import StudyMaterialUpdateForm from "./AdminPages/form/studyMaterialUpdateForm";

function App() {
  const { getToken, user } = AuthUser();
  // console.log(store, 'get store data');
  return (
    <Provider store={store}>
      <ProSidebarProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="/quiz" element={<QuizPage />} />
          <Route path="/examPage" element={<ExamPage />} />
        </Routes>
        {getToken() && (
          <Routes>
            {/* super admin  */}
            {user?.role == "SA" && (
              <Route path="/admin" element={<Dash />}>
                <Route path="/admin" element={<DashboardPage />} />
                <Route path="name" element={<AddExamName />} />
                <Route path="type" element={<AddExamType />} />
                <Route path="subject" element={<AddSubject />} />
                <Route path="Papers" element={<Papers />} />
                <Route path="viewQuestion" element={<ViewQuestions />} />
                <Route
                  path="viewQuestion/:sub/:id/:selfId"
                  element={<ViewQuestions />}
                />
                <Route path="AddQuestion" element={<AddQuestionPage />} />
                <Route
                  path="AddQuestion/:sub/:id/:selfId"
                  element={<AddQuestionPage />}
                />
                <Route path="subject/:sub/:id" element={<AddSyllabus />} />
                <Route
                  path="subject/:sub/:id/:selfId"
                  element={<AddSyllabus />}
                />
                {/* <Route path='questions_templates' element={<QuestionTemplate />} /> */}
                <Route
                  path="add__question_sets"
                  element={<AddQuestionTemplate />}
                />
                <Route path="createPaper" element={<CreatePaper />} />
                <Route path="addUsers" element={<AddUsers />} />
                <Route path="listUsers" element={<ListUsers />} />
                <Route
                  path="editQuestionsPage"
                  element={<EditQuestionPage />}
                />
                <Route path="templatesList" element={<TemplatesList />} />
                <Route path="SetQuestionPaper" element={<SetQuestionPaper />} />
                <Route path="GeneratedPaper" element={<GeneratedPaper />} />
                <Route path="questionsList" element={<QuestionList />} />
                <Route path="editQuestionSets" element={<EditQuestionSets />} />
                <Route
                  path="QuestionsFromExcel"
                  element={<QuestionsFromExcel />}
                />
                <Route
                  path="EditExcelQuestions"
                  element={<EditExcelQuestions />}
                />
                <Route path="StudyMaterial" element={<StudyMaterial />} />

                <Route
                  path="StudyMaterial/update/:study_material_id"
                  element={
                    <StudyMaterialUpdateForm title={"Update Study Material"} />
                  }
                />
                <Route
                  path="StudyMaterialForm"
                  element={<StudyMaterialForm />}
                />
                <Route path="Reports" element={<Reports />} />
                <Route path="TestPaper" element={<TestPaper />} />
                <Route path="PaperPage" element={<PaperPage />} />
                <Route path="AddQuestionMaths" element={<AddQuestionMaths />} />
                <Route path="AppUsers" element={<AppUsers />} />
                <Route path="PointsMaster" element={<PointsMaster />} />
                <Route path="RewardMaster" element={<RewardMaster />} />
                <Route path="AppPackages" element={<AppPackages />} />
                {/* Notifications routes */}
                <Route
                  path="notifications"
                  element={<div>Notifications</div>}
                />
                <Route
                  path="notifications/push"
                  element={<PushNotificationPage />}
                />
                {/* End Notifications routes */}
                {/* News Letter routes */}
                <Route
                  path="newsletter/subscribers"
                  element={<NewsLetterSubscribersPage />}
                />

                {/* App User Subscribers list */}
                <Route
                  path="app_user/subscribers"
                  element={<AppUserSubscribers />}
                />
              </Route>
            )}

            {user?.role == "Admin" && (
              <Route path="/admin" element={<Dash />}>
                <Route path="/admin" element={<DashboardPage />} />
                <Route path="name" element={<AddExamName />} />
                <Route path="type" element={<AddExamType />} />
                <Route path="subject" element={<AddSubject />} />
                <Route path="Papers" element={<Papers />} />
                <Route path="viewQuestion" element={<ViewQuestions />} />
                <Route
                  path="viewQuestion/:sub/:id/:selfId"
                  element={<ViewQuestions />}
                />
                <Route path="AddQuestion" element={<AddQuestionPage />} />
                <Route
                  path="AddQuestion/:sub/:id/:selfId"
                  element={<AddQuestionPage />}
                />
                <Route path="subject/:sub/:id" element={<AddSyllabus />} />
                <Route
                  path="subject/:sub/:id/:selfId"
                  element={<AddSyllabus />}
                />
                {/* <Route path='questions_templates' element={<QuestionTemplate />} /> */}
                <Route
                  path="add__question_sets"
                  element={<AddQuestionTemplate />}
                />
                <Route path="createPaper" element={<CreatePaper />} />
                {/* <Route path="addUsers" element={<AddUsers />} /> */}
                <Route path="listUsers" element={<ListUsers />} />
                <Route
                  path="editQuestionsPage"
                  element={<EditQuestionPage />}
                />
                <Route path="templatesList" element={<TemplatesList />} />
                <Route path="SetQuestionPaper" element={<SetQuestionPaper />} />
                <Route path="GeneratedPaper" element={<GeneratedPaper />} />
                <Route path="questionsList" element={<QuestionList />} />
                <Route path="editQuestionSets" element={<EditQuestionSets />} />
                <Route
                  path="QuestionsFromExcel"
                  element={<QuestionsFromExcel />}
                />
                <Route
                  path="EditExcelQuestions"
                  element={<EditExcelQuestions />}
                />
                <Route path="StudyMaterial" element={<StudyMaterial />} />

                <Route
                  path="StudyMaterial/update/:id"
                  element={<div>update this big boi</div>}
                />
                <Route
                  path="StudyMaterialForm"
                  element={<StudyMaterialForm />}
                />
                <Route path="Reports" element={<Reports />} />
                <Route path="TestPaper" element={<TestPaper />} />
                <Route path="PaperPage" element={<PaperPage />} />
                <Route path="AddQuestionMaths" element={<AddQuestionMaths />} />
                <Route path="AppUsers" element={<AppUsers />} />
                <Route path="PointsMaster" element={<PointsMaster />} />
                <Route path="RewardMaster" element={<RewardMaster />} />
                <Route path="AppPackages" element={<AppPackages />} />
              </Route>
            )}

            {user?.role == "QC" && (
              <Route path="/admin" element={<Dash />}>
                <Route path="/admin" element={<DashboardPage />} />
                <Route path="name" element={<AddExamName />} />
                <Route path="type" element={<AddExamType />} />
                <Route path="subject" element={<AddSubject />} />
                <Route path="Papers" element={<Papers />} />
                <Route path="viewQuestion" element={<ViewQuestions />} />
                <Route
                  path="viewQuestion/:sub/:id/:selfId"
                  element={<ViewQuestions />}
                />
                <Route path="AddQuestion" element={<AddQuestionPage />} />
                <Route
                  path="AddQuestion/:sub/:id/:selfId"
                  element={<AddQuestionPage />}
                />
                <Route path="subject/:sub/:id" element={<AddSyllabus />} />
                <Route
                  path="subject/:sub/:id/:selfId"
                  element={<AddSyllabus />}
                />
                <Route path="questionsList" element={<QuestionList />} />
                <Route
                  path="editQuestionsPage"
                  element={<EditQuestionPage />}
                />
              </Route>
            )}
          </Routes>
        )}

        {!getToken() && (
          <Routes>
            <Route path="/admin/*" element={<Login />} />
          </Routes>
        )}
      </ProSidebarProvider>
    </Provider>
  );
}

export default App;
