import { Button, Chip, Grid, Stack, Typography } from "@mui/material";

import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import useFormState from "../../hooks/useFormState";
import usePushNotificationStatesHook from "./usePushNotificationStatesHook";
import CustomTable from "../table/CustomTable";
import { toast } from "react-toastify";
import api from "../../API/api";

const PushNotificationPage = () => {
  const [selectedUsers, setSelectedUsers] = useState({});
  const [isSendingNotification, setIsSendingNotification] = useState(false);
  const [invalidUsers, setInvalidUsers] = useState([]);

  const { formState, setFormValues } = useFormState({
    title: "",
    body: "",
  });

  const {
    appUsers,
    setAppUsers,
    handleChangePage,
    handleChangeRowsPerPage,
    changeFilters,
    loading,
  } = usePushNotificationStatesHook();

  const handleRowClick = (row) => {
    if (selectedUsers[row.id]) {
      delete selectedUsers[row.id];
      setSelectedUsers({ ...selectedUsers });
    } else {
      setSelectedUsers({ ...selectedUsers, [row.id]: row });
    }
  };

  const handleSendNotification = (type) => {
    console.log("the form state", formState);
    // first checking errors
    if (!formState.title || !formState.body) {
      toast.error("Please fill in the title and body");
      return;
    }

    // if both and title are valdi lets go
    if (type === "selected") {
      if (Object.keys(selectedUsers).length < 1) {
        toast.error("Please select at least one user");
        return;
      }
      setIsSendingNotification(true);
      api
        .sendPushNotificationToSelectedUsers({
          title: formState.title,
          body: formState.body,
          users: Object.keys(selectedUsers),
        })
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }

          if (res.data.invalid_users) setInvalidUsers(res.data.invalid_users);
        })
        .catch((error) => {
          toast.error("Failed to send notification to all users");
        })
        .finally(() => {
          setIsSendingNotification(false);
        });
      // send to selected users
    } else {
      // send to all users
      setIsSendingNotification(true);
      api
        .sendPushNotificationToAllUsers({
          title: formState.title,
          body: formState.body,
        })
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }

          if (res.data.invalid_users) setInvalidUsers(res.data.invalid_users);
        })
        .catch((error) => {
          toast.error("Failed to send notification to all users");
        })
        .finally(() => {
          setIsSendingNotification(false);
        });
    }
  };

  /**
   * Table Heads for the table
   * id: the id of the column
   * @type {import("../table/custom_table__jsdoc_types").TableHeadItem[]} tableHeads
   */
  const tableHeads = [
    {
      id: "id",
      label: "ID",
      editable: true,
      minWidth: 30,
      maxWidth: 80,
      onChange: (e) => changeFilters("id", e.target.value),
    },
    {
      id: "f_name",
      label: "First Name",
      editable: true,
      onChange: (e) => changeFilters("firstName", e.target.value),
    },
    {
      id: "l_name",
      label: "Last Name",
      editable: true,
      onChange: (e) => changeFilters("lastName", e.target.value),
    },
    {
      id: "email",
      label: "Email",
      editable: true,
      onChange: (e) => changeFilters("email", e.target.value),
    },
    {
      id: "phone_number",
      label: "Phone",
      editable: true,
      numeric: true,
      onChange: (e) => changeFilters("phone", e.target.value),
    },
  ];

  return (
    <Stack direction={"column"} flex={1} minHeight={""}>
      <Typography variant="h4">Push Notification</Typography>
      <Stack direction={"row"} flexWrap={"wrap"} py={2}>
        Title: {formState.title}, Body: {formState.body}
      </Stack>
      <Grid container paddingTop={1}>
        <Grid item md={3} px={1}>
          <TextField
            id="title"
            label="Title"
            variant="outlined"
            fullWidth
            onChange={(e) => setFormValues("title", e.target.value)}
          />
        </Grid>
        <Grid sm={12} item md={3} px={1}>
          <TextField
            id="body"
            label="Body"
            variant="outlined"
            fullWidth
            onChange={(e) => setFormValues("body", e.target.value)}
          />
        </Grid>
        <Grid item md={3} px={1}>
          <Button
            variant="contained"
            fullWidth
            sx={{ height: "100%" }}
            disabled={
              Object.keys(selectedUsers).length < 1 || isSendingNotification
            }
            onClick={() => handleSendNotification("selected")}
          >
            Send to Selected User
          </Button>
        </Grid>
        <Grid item md={3} px={1}>
          <Button
            onClick={() => handleSendNotification("all")}
            variant="contained"
            fullWidth
            sx={{ height: "100%" }}
            disabled={isSendingNotification}
          >
            Send to All Users
          </Button>
        </Grid>
      </Grid>
      <Stack direction={"column"} flex={1} py={2}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="h6">
              Selected Users: {Object.keys(selectedUsers).length}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction={"row"} flexWrap={"wrap"}>
              {Object.keys(selectedUsers).map((key) => (
                <Chip
                  sx={{
                    mx: 1,
                    my: 0.5,
                  }}
                  label={selectedUsers[key].email}
                  onDelete={() => handleRowClick(selectedUsers[key])}
                />
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Stack>
      <Stack>
        <CustomTable
          tableRows={appUsers && appUsers.data ? appUsers.data : []}
          tablePagination={appUsers}
          tableHeads={tableHeads}
          rowClick={handleRowClick}
          selectedItems={selectedUsers}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          loading={loading}
        />
      </Stack>
    </Stack>
  );
};

export default PushNotificationPage;
