import React, { useEffect, useState } from "react";
import api from "../../API/api";
import { Paper, TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import DialogModal from "../modal/modal";
import ConfirmModal from "../pannel/confirmModal";
import TablePagination from "@mui/material/TablePagination";
import useDebounce from "../../hooks/useDebounce";
import MemoizedSkeletonRows from "./SkeletonRows";

function PaperList({ setBackdropLoader }) {
  const [paperList, setPaperList] = useState();
  const [paginateOptions, setPaginateOptions] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedValue, setSelectedValue] = useState();
  const [openModal, setOpenModal] = useState();
  const navigate = useNavigate();

  const [loading, setLoading] = useState();

  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [filters, setFilters] = useState({
    paperName: "",
    totalQuestions: "",
    marksPerQuestion: "",
    paperType: "",
  });

  const changeFilters = (key, value) => {
    setFilters({ ...filters, [key]: value });
    setLoading(true);
  };

  const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;

  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedValue(data);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const listPaper = (page, row, filters) => {
    if (!page || !row) {
      setBackdropLoader(true);
    }

    let params = {};
    if (page && row) {
      params = {
        params: {
          page,
          total_rows: rowsPerPage,
          ...filters,
        },
      };
    }

    api
      .listPaper(params)
      .then((res) => {
        let paperData = res.data.papers;

        setPaperList(paperData.data);
        delete paperData.data;
        setPaginateOptions(paperData);
        // console.log(res, "res papaer list");
        setBackdropLoader(false);
      })
      .catch((err) => {
        console.log(err, "err paper list");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const debouncedFetchUsers = useDebounce((filters) => {
    listPaper(page + 1, rowsPerPage, filters);
  }, 300);

  useEffect(() => {
    listPaper(page + 1, 5, filters);
  }, []);

  const handleClickEdit = (data) => {
    // console.log(selectedValue, "data2");
    navigate("/admin/createPaper", {
      state: { value: selectedValue && selectedValue, hasEdit: "edit" },
    });
  };
  const handleDelete = (data) => {
    console.log(selectedValue && selectedValue, "data2");
    // return;
    // return;
    api
      .deletePaper(selectedValue.id)
      .then((res) => {
        // console.log(res, "res");
        setPaperList(paperList?.filter((o) => o.id !== selectedValue.id));
        setAnchorEl(null);
        setOpenModal(false);
        // console.log(dasd,'dasd');
        Swal.fire({
          title: "Deleted",
          text: "Exam Paper is deleted",
        });
      })
      .catch((err) => {
        console.log(err, "err");
        Swal.fire({
          title: "Something Went wrong",
          text: "Please try again",
        });
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setLoading(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setLoading(true);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  useEffect(() => {
    debouncedFetchUsers(filters);
    // listPaper(page + 1, rowsPerPage, filters);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(0);
    setRowsPerPage(5);

    // only send filters that have a string value more than 0
    const filteredFilters = Object.entries(filters).reduce(
      (acc, [key, value]) => {
        if (typeof value === "string" && value.length > 0) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    debouncedFetchUsers(filteredFilters);
  }, [filters]);

  const tableHeads = [
    {
      id: "slno",
      label: "Sl no.",
      minWidth: 100,
      maxWidth: 100,
      // className: "text-white",
      align: "left",
    },
    {
      id: "paperName",
      label: "Paper Name",
      minWidth: 150,
      maxWidth: 150,
      // className: "text-white",
      align: "left",
      editable: true,
      value: filters.paperName,
      onChange: (e) => changeFilters("paperName", e.target.value),
    },
    {
      id: "description",
      label: "Description",
      minWidth: 100,
      maxWidth: 100,
      // className: "text-white",
      align: "left",
    },
    {
      id: "totalQuestions",
      label: "Total Questions",
      minWidth: 100,
      maxWidth: 100,
      // className: "text-white",
      align: "center",
      editable: true,
      value: filters.totalQuestions,
      onChange: (e) => changeFilters("totalQuestions", e.target.value),
    },
    {
      id: "marksPerQuestion",
      label: "Marks Per Question",
      minWidth: 150,
      maxWidth: 150,
      // className: "text-white",
      align: "center",
      editable: true,
      value: filters.marksPerQuestion,
      onChange: (e) => changeFilters("marksPerQuestion", e.target.value),
    },
    {
      id: "negativeMarks",
      label: "Negative Marks",
      minWidth: 100,
      maxWidth: 100,
      // className: "text-white",
      align: "center",
    },
    {
      id: "paperType",
      label: "Paper Type",
      minWidth: 100,
      maxWidth: 100,
      // className: "text-white",
      align: "center",
      editable: true,
      editType: "select",
      defaultValue: "paid",
      value: filters.paperType,
      onChange: (e) => changeFilters("paperType", e.target.value),
      selectOptions: [
        {
          value: "",
          label: "All",
        },
        {
          value: "paid",
          label: "Paid",
        },
        {
          value: "free",
          label: "Free",
        },
        {
          value: "live",
          label: "Live",
        },
      ],
    },
    {
      id: "action",
      label: "Action",
      minWidth: 100,
      maxWidth: 100,
      // className: "text-white",
      align: "center",
    },
  ];

  return (
    <Paper elevation={5}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead

          // sx={{  color: "black" }}
          >
            <TableRow
              sx={{
                paddingY: "10px",
              }}
            >
              <TableCellGenerator tableCells={tableHeads} />
            </TableRow>

            {/* <TableRow>
              <TableCell className="text-white" align="left">
                Sl no.
              </TableCell>
              <TableCell className="text-white" align="left">
                Paper Name
              </TableCell>
              <TableCell className="text-white" align="left">
                Description
              </TableCell>
              <TableCell className="text-white" align="center">
                Total Questions
              </TableCell>
              <TableCell className="text-white" align="center">
                marks per Question
              </TableCell>
              <TableCell className="text-white" align="center">
                Negative Marks
              </TableCell>
              <TableCell className="text-white" align="center">
                Paper Type
              </TableCell>
              <TableCell className="text-white" align="center">
                Action
              </TableCell>
            </TableRow> */}
          </TableHead>
          <TableBody>
            {loading && <MemoizedSkeletonRows tableHeads={tableHeads} />}
            {!loading &&
              paperList?.map((data, index) => (
                <>
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {data.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {data.description}
                    </TableCell>
                    <TableCell align="right">{data.tot_questions}</TableCell>
                    <TableCell align="right">
                      {data.marks_per_question}
                    </TableCell>
                    <TableCell align="right">{data.negative_marks}</TableCell>
                    <TableCell align="right">{data.paper_type}</TableCell>
                    <TableCell align="right">
                      <Stack spacing={1} className="text-center">
                        <IconButton
                          aria-label="more"
                          onClick={(e) => handleClick(e, data)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Stack>

                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={() => handleClickEdit()}>
                          Edit
                        </MenuItem>
                        <MenuItem onClick={() => setOpenModal(true)}>
                          Delete
                        </MenuItem>
                      </StyledMenu>
                    </TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={paginateOptions.total ? paginateOptions.total : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <DialogModal
        open={openModal}
        form={
          <ConfirmModal
            handleDelete={handleDelete}
            handleCloseModal={handleCloseModal}
          />
        }
        handleCloseMove={handleCloseModal}
      />
    </Paper>
  );
}

/**
 * Table Cell Generator
 *
 * @param {object} params
 * @param {import('./table_jsdoc_types').TableCellGeneratorParam} params.tableCells
 */
const TableCellGenerator = ({ tableCells }) => {
  return tableCells.map((tableCell) => {
    // check if its editable
    if (tableCell.editable) {
      // check if editType is "select"
      if (tableCell.editType === "select") {
        return (
          <TableCell
            key={tableCell.id}
            sx={{
              minWidth: tableCell.minWidth ? tableCell.minWidth : 100,
              paddingY: 2,
            }}
            align={tableCell.align}
          >
            <TextField
              sx={{
                minWidth: tableCell.minWidth ? tableCell.minWidth : 100,
              }}
              label={tableCell.label}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              select
              onChange={(e) => {
                tableCell.onChange(e);
              }}
              // onChange={(e) => handleChange(e, tableCell)}
            >
              {tableCell.selectOptions.map((option, index) => (
                <MenuItem
                  sx={{
                    minHeight: "1rem",
                  }}
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>
        );
      }

      // return a text field with label shrinked
      // if editType === "text" or nothing is provided
      return (
        <TableCell
          key={tableCell.id}
          sx={{
            minWidth: tableCell.minWidth ? tableCell.minWidth : 100,
            // cursor: "text"
          }}
          align={tableCell.align}
        >
          <TextField
            sx={{
              minWidth: tableCell.minWidth ? tableCell.minWidth : 100,

              "& .MuiOutlinedInput-root": {},
            }}
            label={tableCell.label}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => tableCell.onChange(e)}
          />
        </TableCell>
      );
    }

    return (
      <TableCell
        sx={{
          minWidth: tableCell.minWidth ? tableCell.minWidth : 100,
          ...tableCell.sx,
        }}
        align={tableCell.align}
      >
        {tableCell.label}
      </TableCell>
    );
  });
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
export default PaperList;
